import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import work from '../static/list_work';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles((theme) => ({
  root:{
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  divider:{
    backgroundColor: theme.palette.divider,
    maxWidth: '100%',
  }

}));

export default function Services() {
  const classes = useStyles();
  return (
    <Container className={classes.divider}>
      <Container className={classes.root} maxWidth="md">
        <Grid container spacing={4}>
          <Grid item>
            <Typography variant='h4' id='services' gutterBottom>
            My Services
            </Typography>
            <Typography variant="body1">
            How can I help? As a freelance data engineer I can do the following:
            </Typography> 
            <List>
              <ListItem>
                <KeyboardArrowRightIcon/>
                Full day data engineering work (minimum booking 8h)
              </ListItem>
              <ListItem>
                <KeyboardArrowRightIcon/>
                Data strategy sessions (future data roadmap)
              </ListItem>   
              <ListItem>
                <KeyboardArrowRightIcon/>
                Data engineering trainings (Python, SQL, Airflow, DBT)
              </ListItem>
              <ListItem>
                <KeyboardArrowRightIcon/>
                Google Cloud Platform trainings (BigQuery, Cloud Function, Cloud Run, Cloud Composer, Dataflow, etc.)
              </ListItem>           
            </List>


            <Typography variant="body1">
            To give you an understanding of the data engineerings work I can do, I've listed the things I do regularly: 
            </Typography> 
            <List>
              {work.map((work) => (
                <ListItem alignItems="flex-start" key={work.title}>
                  <ListItemAvatar>
                    <Avatar alt={work.title} src={work.tile} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={work.title}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          color="textPrimary"
                        >
                          {work.subtitle}
                        </Typography>
                          {work.description}
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Grid>      
        </Grid>
      </Container>
    </Container>
  );
}