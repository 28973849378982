import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import Container from '@material-ui/core/Container'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link'

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1, 
  },
  menu:{
    backgroundColor: theme.palette.background.paper,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  // appbarWrapper: {
  //   // width: '80%',
  //   margin: '0 auto',
  // },

}));

export default function MenuAppBar(props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    window.dataLayer.push({'event':'menu','value':'open'})
  };

  const handleCta = (event) => {
    window.dataLayer.push({'event':'cta','value':'contact'})
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    window.dataLayer.push({'event':'menu','value':'item - ' + event.target.href})
  };

  return (
    <HideOnScroll {...props}>
          <AppBar className={classes.menu} >
            <Container maxWidth="md">
              <Toolbar >
                <IconButton 
                  edge="start" 
                  className={classes.menuButton} 
                  color="inherit" 
                  aria-label="menu" 
                  aria-controls='fade-menu'
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose} component={Link} href="#vision" color="inherit">My Vision</MenuItem>
                  <MenuItem onClick={handleClose} component={Link} href="#services" color="inherit">My Services</MenuItem>
                  <MenuItem onClick={handleClose} component={Link} href="#skills" color="inherit">My Skills</MenuItem>
                  <MenuItem onClick={handleClose} component={Link} href="#contact" color="inherit">Contact</MenuItem>
                </Menu>                
                <Typography variant="h6" className={classes.title}>
                  Menu
                </Typography>
                <Button variant='contained' color="primary" href='#contact' onClick={handleCta}>
                  Contact
                </Button>
              </Toolbar>
            </Container>
          </AppBar>
        
    </HideOnScroll>
  );
}
