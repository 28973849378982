import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root:{
    marginTop: theme.spacing(8),
    justify: 'space-around',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    minHeight: '400px'
  },
  me:{
    width: theme.spacing(12),
    height: theme.spacing(12),
  }, 
  divider:{
    backgroundColor: theme.palette.divider,
    maxWidth: '100%',
  },  
  textbox:{
    display: 'flex',
    justify:'center',
    alignItems:'center',
  }
}));

export default function About() {
  const classes = useStyles();
  return (
    <Container className={classes.divider}>
      <Container className={classes.root} maxWidth="md">
        <Typography variant='h4' id='about' gutterBottom>
          About Me
        </Typography>
        <Grid container spacing={4} className={classes.textbox}>
          <Grid item xs={9} >
            <Typography variant="body1">
              My name is Jasper, a social data nerd. I love to learn new things
              and apply them in my daily work. This way, my work will be
              better every iteration. Actually, there is no other option, since 
              data engineering changes constantly. I'm passionate about data warehousing,
              data pipelines and automation. My developed skills in Python, SQL and cloud 
              services help me to build complete data architectures. The field 
              of business does not matter to me, the need for data is everywhere. 
            </Typography>           
          </Grid>  
          <Grid item xs={3} >
            <Avatar alt="Jasper" src="/assets/tile-jasper.jpg" className={classes.me}/>
          </Grid>      
        </Grid>
      </Container>
    </Container>
  );
}