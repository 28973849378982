const work = [
  {
    title: 'Airflow',
    subtitle: 'Workflow Orchestration | ETL - ',
    description:
      "Or actually, EL(T). Since the transformation step is done within the data model and not prior loading. I can fully manage your Airflow setup, from hosting on cloud services to custom hooks and operators. I've integrated cross cloud connectors, built custom connectors with 3rd party api's and setup monitoring alerts.",
    tile: '/assets/tile-airflow.png',
  },
  {
    title: 'Data Warehousing',
    subtitle: 'Data Modeling | SQL - ',
    description:
      "This is where the magic happens. It's the most important step for a proper data warehouse. I build modular SQL scripts and deploy them with an workflow orchestrator (Airflow/DBT) using a CI/CD (continious integration/continuos deployment) approach. All my SQL's are versioned with git and with new commits trigger new cloud builds.",
    tile: '/assets/tile-data_warehousing.png',
  },
  {
    title: 'Beam',
    subtitle: 'Realtime Pipelines | Distributed Data Processing - ',
    description:
      "Do you need realtime data processing? Beam is your friend. I can build serverless setups for your streaming and batch data pipelines. It handles event logging, data enrichments and distributed data processing, all in one packages.",
    tile: '/assets/tile-dataflow.png',
  },    
];

export default work;