import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  footer: {
    //backgroundColor: theme.palette.divider,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    minHeight: '400px',
    alignItems: 'center'
  },

}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      {'Copyright © '}
      {'This Is Data '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


export default function FooterBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
    <footer className={classes.footer}>
      <Grid 
        container spacing={3}   
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Grid item >
          <Typography variant="h6">
            Thanks for visiting 🤗
          </Typography>
        </Grid>
        <Grid item >
          <Link href="https://www.linkedin.com/in/jasper-jansen-92a63250/">
            <LinkedInIcon style={{fill: "white"}}/>
          </Link>
          <Link href="https://github.com/jasperjansen">
            <GitHubIcon style={{fill: "white"}}/>
          </Link>
        </Grid>
        <Grid item >
          <Typography variant="subtitle1" component="p">
            Go big data or go home!
          </Typography>
        </Grid>
        <Grid>
          <Typography variant="body2" align="center" color="textSecondary">
            KvK: 80916260
          </Typography>
          <Typography variant="body2" align="center" color="textSecondary">
            BTW-id: NL003507875B79
          </Typography>
          <Typography variant="body2" align="center" color="textSecondary">
            Startdatum: 20-11-2020
          </Typography>                    
        </Grid>
        <Grid item >
          <Copyright />
        </Grid>

      </Grid>



      
    </footer>
    </div>
  );
}