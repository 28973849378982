import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import skills from '../static/list_skills';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  root:{
    justify: 'space-around',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  title:{
    fontWeight: 'bold'
  },
  card:{
    height: '100%'
  },
  divider:{
    backgroundColor: theme.palette.divider,
    maxWidth: '100%',
  },
  rating:{
    justify:"flex-end",
    alignItems:"flex-end",
    bottom: 0,
    left: 0
  }
}));

export default function SkillGrid() {
  const classes = useStyles();
  return (
    //<Container className={classes.divider}>
      <Container className={classes.root} maxWidth="md">
        <Typography variant='h4' id='skills' gutterBottom>
          My Skills
        </Typography>
        <Typography variant="body1">
          I want to stay on top of the data game. How do I do that? 
          I've created a solid foundation and learned to learn fast. 
          My foundation includes 4+ years of data engineering experience, a strategic mindset, problem solving-, consultancy- and analytical skills.
          Here's an overview of my skills. There's much more, but this is just the main compartment of the backpack:
        </Typography>  
        <br></br>
            <Grid container spacing={4}>
              {skills.map((skill) => (
                <Grid item key={skill.skill} xs={12} sm={6} md={6}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Grid container alignItems="center">
                        <Grid item xs={10}>
                          <Typography className={classes.title} color="textSecondary" variant="h5">
                              {skill.skill}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Avatar alt={skill.skill} src={skill.tile} />
                        </Grid>
                      </Grid>
                      <Typography variant="body2" component="p">
                        {skill.description}
                      </Typography>
                      <br></br>
                      <Grid container className={classes.rating}>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6}>
                          <Rating name="read-only" value={skill.rating} readOnly />
                        </Grid>
                      </Grid>
                      
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          <br></br>
      </Container>
    //</Container>
  );
}