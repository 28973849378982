const skills = [
  {
    skill: 'Python',
    description:
      "This is by far my favorite and most mature language. I started in 2008 during University to analyse DNA sequences. Since that moment, I've used it for all my data projects. I'm familiar with most data related libraries, if not, I'm eager to learn it. ",
    tile: '/assets/tile-python.png',
    rating: 5
  },
  {
    skill: 'SQL',
    description:
      "Old but gold. The most powerfull language to speak fluently as a data engineer. SQL has never let me down. It makes me happy to build a proper data model which helps the business with their needs.",
    tile: '/assets/tile-sql.png',
    rating: 5
  },
  {
    skill: 'Google Cloud',
    description:
      "I love it. I fell in love during big data projects related to marketing. In marketing it's about realtime data. GCP offers a wide set of services to build a propriate architecture.",
    tile: '/assets/tile-google_cloud.png',
    rating: 4
  },
  {
    skill: 'CI/CD',
    description: "All my projects are CI/CD based. My code is versioned with git and all pull requests trigger a cloud build automatically. I don't make exceptions for data warehouses, all SQL's are versioned and automatically triggered when needed.",
    tile: '/assets/tile-cicd.png',
    rating: 4
  },
  {
    skill: 'Docker',
    description: "To build scalable solutions I had to learn working with Docker. So I did beginning of 2020. I'm not there yet, but I do feel comfortable already with building custom apps and deploy them with e.g. Cloud Run.",
    tile: '/assets/tile-docker.png',
    rating: 3
  },  
  {
    skill: 'React',
    description: "Alright, I'm not a web developer, but I did build this website. Why? Because I wanted to learn something new. It's build with Google's Material Design system. Deployed in Google Cloud Run (Serverless, scales infinitely [or actually, I limited it to 2 machines for you hackers out there;)]). Domain setup via Transip. I even handle my own forms with an api subdomain 🤓",
    tile: '/assets/tile-react.png',
    rating: 2
  },
];

export default skills;