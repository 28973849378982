
import React, { useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import SendIcon from '@material-ui/icons/Send';
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar';

const useStyles = makeStyles((theme) => ({
  root:{
    marginTop: theme.spacing(8),
    justify: 'space-around',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  divider:{
    backgroundColor: theme.palette.divider,
    maxWidth: '100%',
  },
  form:{
    //backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
  },
  formRaster:{
    justifyContent: 'flex-end'
  },
  textWhiteBorder:{
    '& label.Mui-focused': {
      color: `${theme.palette.text.primary}`,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: `${theme.palette.text.primary}`,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: `${theme.palette.text.primary}`,
      },
      '&:hover fieldset': {
        borderColor: `${theme.palette.text.primary}`,
      },
      '&.Mui-focused fieldset': {
        borderColor: `${theme.palette.text.primary}`,
      },
    }
  }
}));

// https://codesandbox.io/s/react-material-ui-form-submit-v40lz?from-embed=&file=/src/components/MaterialUIFormSubmit.js:12-28

export default function ContactForm() {
  const [open, setOpen] = React.useState(false);
  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      fname: "",
      lname: "",
      email: "",
      message: ""
    }
  );

  const handleSubmit = event => {
    event.preventDefault();

    let data = { formInput };

    fetch("https://api.thisisdata.nl/contact", {
      method: "POST",
      body: JSON.stringify(data.formInput),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => console.log("Success:", response))
      .then(response => setOpen(true))
      .then(response => window.dataLayer.push({'event':'contact','value':'success'}))
      .catch(error => console.error("Error:", error))
      .catch(error => window.dataLayer.push({'event':'contact','value':error}))
  };

  const handleChange = event => {
    const name = event.target.name;
    const newValue = event.target.value;
    setFormInput({ [name]: newValue });
  };

  const classes = useStyles();

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Container className={classes.divider}>
    <Container className={classes.root} maxWidth="md">
    <Typography variant='h4' id="contact" gutterBottom>
      Contact
    </Typography>
    <Typography variant="body1">
      Like what you see? Want to know if I'm available? Do you have questions? 
      Feel free to reach out with the contact form. Your message will go into my 
      inbox and I'll answer you as soon as possible. 
    </Typography> 
    <Container maxWidth = 'sm'>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseAlert} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
        <Alert onClose={handleCloseAlert} severity="success" elevation={6} variant="filled">
          Your message has been send :)
        </Alert>
      </Snackbar>
      <form className={classes.form} autoComplete = 'on' onSubmit={handleSubmit}>
        <Grid container spacing={2} className={classes.formRaster} >
          <Grid item xs={6}>
            <TextField className={classes.textWhiteBorder}
              autoComplete="fname"
              name="fname"
              variant="outlined"
              required
              fullWidth
              label="First Name"
              value={formInput.fname} 
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField className={classes.textWhiteBorder}
              variant="outlined"
              required
              fullWidth
              label="Last Name"
              name="lname"
              autoComplete="lname"
              value={formInput.lname} 
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField className={classes.textWhiteBorder}
              variant="outlined"
              required
              fullWidth
              label="Email Address"
              name="email"
              autoComplete="email"
              value={formInput.email} 
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField className={classes.textWhiteBorder}
              variant="outlined"
              required
              fullWidth
              multiline
              rows={5}
              id="message"
              label="Message"
              name='message'
              value={formInput.message} 
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <Button
              type='submit'
              variant="contained"
              color="primary"
              endIcon={<SendIcon></SendIcon>}
            >
              Send
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
    </Container>
    </Container>
  );
}
      