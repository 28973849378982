import { CssBaseline } from '@material-ui/core';
import Welcome from './components/welcome'
import MenuAppBar from './components/menu';
import SkillGrid from './components/skills';
import Services from './components/services'
import About from './components/about'
import Vision from './components/vision'
import FooterBar from './components/footer';
import ContactForm from './components/contact'
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import teal from '@material-ui/core/colors/teal';
import 'fontsource-roboto';
import Cookiebar from './components/cookiebar'

//https://medium.com/heuristics/react-dark-mode-switch-in-material-ui-dashboard-82fcf1cded66
// test

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: teal,
  }
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <MenuAppBar />
        <Welcome />
        <About />
        <Vision />
        <Services />
        <SkillGrid />
        <ContactForm />
        <FooterBar />
        <Cookiebar />
    </ThemeProvider>
  );
}

export default App;
