import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}; 

const getCookie = (cname) => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const useCookie = (key) => {
  const getItem = () => getCookie(key);
  const consentGivenBoolean = () => {if(getItem() === 'true' || getItem() === 'false'){return false} else{return true}}
  const [cookie, setCookieState] = React.useState(consentGivenBoolean());
 
  const updateCookie = (value, days) => {
    setCookieState(false);
    setCookie(key, value, days);
  };

  return [cookie, updateCookie];
};


export default function Cookiebar() {
  const [consent, setConsent] = useCookie("CookieConsent")

  const handleYes = () => {
    setConsent('true', 365)
    window.dataLayer.push({'event':'consent','value':'true'})
  };

  const handleNo = () => {
    setConsent('false', 365)
    window.dataLayer.push({'event':'consent','value':'false'})
  };


  return (

      <Dialog
        open={consent}
        TransitionComponent={Transition}
        keepMounted
        disableBackdropClick
        //onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Do you accept cookies?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            This website uses cookies to analyse traffic.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYes} color="primary" variant="contained">
            Yes
          </Button>
          <Button onClick={handleNo} color="secondary" variant="contained">
            No
          </Button>
        </DialogActions>
      </Dialog>
  );
}

