import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography';
import Image from 'material-ui-image';
import CardMedia from '@material-ui/core/CardMedia';
import TransformIcon from '@material-ui/icons/Transform';
import LayersIcon from '@material-ui/icons/Layers';
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  root1:{
    paddingTop: theme.spacing(12), // move below menubar
    paddingBottom: theme.spacing(4),
  },
  root2:{
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
  },
  root3:{
    display: 'flex',
    alignItems:'center',
    justify: 'center',
    textAligh: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
  container:{
    display: 'flex',
    alignItems:'center',
    justify: 'center',
    textAligh: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
  avatar:{
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: theme.palette.action.active
  },
  icon:{
    color: theme.palette.primary
  },
  approach:{
    minHeight: '150px',
    //alignItems: 'center' //vertical middle row
  },
  logo: {
    width: theme.spacing(24),
    height: theme.spacing(24),
  },
}));

export default function Welcome() {
  const classes = useStyles();
  return (
    <Container className={classes.root1} maxWidth="md">
      <div>
        <Grid container spacing={4} className={classes.container}>
          <Grid item xs={12} sm={3} className={classes.container}>
            <Avatar 
              className={classes.logo}
              variant="square"
              alt='logo' 
              src={process.env.PUBLIC_URL + '/assets/this_is_data_black.png'} 
            />
          </Grid>
          <Grid item xs={12} sm={9} style={{fontStyle: 'italic', textAlign: 'center'}}>
            <Typography variant='h5'>
              "Hi, I'm Jasper. I'm a
            </Typography>
            <Typography variant='h5' >
              freelance data engineer.
            </Typography>
            <Typography variant='h5' >
              How can I help?"
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container spacing={4} className={classes.root2}>
          <Grid item xs={12} className={classes.root2}>
            <Typography variant="subtitle1" className={classes.root2}>
            I can help your business to build a future-ready data architecture.
            As a certified Google Cloud Data Engineer and Msc in Medical Engineering, 
            I've got a wide set of skills to serve your company. From intake to implementation, 
            I'm a one-stop-shop for all of your data needs. My data approach is simple:
            </Typography>       
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container spacing={4} className={classes.container} >
          <Grid item xs={3} md={1}>
            <Avatar className={classes.avatar}>
              <LayersIcon className={classes.icon} />
            </Avatar>
          </Grid>
          <Grid item xs={9} md={3}>
            <Typography variant="button" display="block" gutterBottom>
              Load
            </Typography>
          </Grid>
          
          <Grid item xs={3} md={1}>
            <Avatar className={classes.avatar}>
              <TransformIcon className={classes.icon} />
            </Avatar>
          </Grid>
          <Grid item xs={9} md={3}>
            <Typography variant="button" display="block" gutterBottom>
              Transform
            </Typography>
          </Grid>
          <Grid item xs={3} md={1}>
            <Avatar className={classes.avatar}>
              <PlayCircleOutlineOutlinedIcon className={classes.icon} />
            </Avatar>
          </Grid>
          <Grid item xs={9} md={3}>
            <Typography variant="button" display="block" gutterBottom>
              Activate
            </Typography>
          </Grid>
        </Grid>
      </div>
      
    </Container>
  );
}