import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root:{
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    minHeight: '400px'
  },
  divider:{
    //backgroundColor: theme.palette.divider,
    maxWidth: '100%',
  },  
  textbox:{
    //justify:'flex-start',
    //alignItems:'center'
  }
}));

export default function Vision() {
  const classes = useStyles();
  return (
    <Container className={classes.divider}>
    <Container className={classes.root} maxWidth="md">
      <Typography variant='h4' id='vision'gutterBottom>
        My Vision
      </Typography>
      <Grid container className={classes.textbox}>
        <Grid item xs = {12} md={8}>

          <Typography variant="body1">
            It's your data: own it, use it, master it! Your data should be collected 
            in one place in, don't leave it on different systems and let people work 
            in silos. Bring it together in one data warehouse and make sure this is the starting
            point for all your teams. When you collect it, collect raw data only. This 
            allows you to always trace back data analysis to the origin. Model your data in schemas, 
            all of which should have a different purpose. If your goal is to serve a 
            realtime dashboard, build the architecture accordingly. If the goal is to 
            build daily reports, aggregate accordingly. If you want to filter on specific features,
            make sure they are available everywhere. 
          </Typography>           
        </Grid>        
        <Grid item xs={12} md={4}>
          <Timeline>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>Load</TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>Transform</TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
              </TimelineSeparator>
              <TimelineContent>Activate</TimelineContent>
            </TimelineItem>
          </Timeline>
        </Grid>
      </Grid>
      
    </Container>
    </Container>
  );
}